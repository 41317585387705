import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import logo from "../../Assets/logo.png";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import ExperienceCard from "./ExperienceCard";
import StarsCanvas from "../Stars";

import { Link } from "react-router-dom";
import {
  FaArrowRight,
  FaArrowLeft,
} from "react-icons/fa";
import { Helmet } from "react-helmet";

const Experience = () => {
  return (
    <Container fluid className="project-section">
      <Helmet>
        <link rel="canonical" href="https://www.singhteekam.in/experience" />
      </Helmet>
      <StarsCanvas />
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Professional Experience </strong>
        </h1>
      </Container>

      <Container>
      <VerticalTimeline>
        <ExperienceCard
          experience={{
            role: "React.js Developer",
            company: "TATA CONSULTANCY SERVICES",
            date: "Aug 2022 - Present",
            logo: logo,
            description: [
              "Built a responsive front-end using React.js and Material UI for seamless cross-device experience.",
              "Designed and deployed a data visualization dashboard with React.js and Chart.js for interactive data insights.",
              "Developed custom React hooks to improve code reusability and maintainability, reducing development time by 40% across multiple components and enhancing overall project scalability",
              "Collaborated with cross-functional teams to translate Figma layouts into responsive React components.",
              "Managed version control with Git and GitHub, ensuring efficient code collaboration and tracking.",
              "Authored unit tests using Jest to maintain high-quality, maintainable, and bug-free code.",
              "Engineered and optimized RESTful APIs in Node.js, leading to a 40% reduction in response time and significantly improving the overall efficiency and scalability of the system.",
              "Automated scheduled tasks with cronjobs, cutting manual intervention by 50% and boosting data processing efficiency",
              "Enhanced performance by reducing page load time by 30% through lazy loading technique.",
            ],
          }}
        />

        <ExperienceCard
          experience={{
            role: "Flutter Developer (Internship)",
            company: "Petgroomers",
            date: "Apr 2021 - June 2021",
            logo: logo,
            description: [
              "To create fully functional application using Flutter and Firebase.",
              "Collaborating with cross-functional teams including designers, product managers, and other developers to create high-quality products.",
              "Implementing responsive design and ensuring cross-browser compatibility.",
              "Participating in code reviews and providing constructive feedback to other developers.",
            ],
          }}
        />

        <ExperienceCard
          experience={{
            role: "Technical Reviewer (Internship)",
            company: "GeeksforGeeks",
            date: "Apr 2022 - Jun 2022",
            logo: logo,
            description: ["Reviewed more than 250+ technical blogs",
            ],
          }}
        />

        <ExperienceCard
          experience={{
            role: "Technical Writer (Internship)",
            company: "GeeksforGeeks",
            date: "Jan 2021 - Mar 2022",
            logo: logo,
            description: ["Published more than 140+ articles on their official website.",
              "Awarded Geek of the Month | March 2022",
              "Awarded Excellent Contribution in Technical Content Writing"
            ],
          }}
        />
      </VerticalTimeline>

      <div className="prev-next">
          <Link className="btn btn-outline-success prevnext-btn" to="/projects">
            <FaArrowLeft /> Projects
          </Link>

          <Link className="btn btn-outline-success prevnext-btn" to="/">
            Home <FaArrowRight />
          </Link>
        </div>
      </Container>
    </Container>
  );
};

export default Experience;
